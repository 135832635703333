import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Map from "../../components/Map";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Instance.css";
import SupportGroupCardDyn from "../../components/SupportGroupCardDyn";
import DrugTypeCardDyn from "../../components/DrugTypeCardDyn";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingIndicator from "../../components/loading_indicator";

function HelpCenterInstance() {
  const { id } = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // load the data from the API as json
      console.log("Fetching data from API...");
      const instance_id = parseInt(id);
      const data_response = await axios.get('https://api.txsubstancefree.me/helpcenters/helpCenterById/', { params: { id: instance_id } })
        .then((response) => {
          setData(response.data);
          console.log("Data fetched from API:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching data from API:", error);
        });
      console.log(data_response);
    }

    fetchData();
  }, [id]);
    
  if (!data) {
    return (
      <div className="container mt-5">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="custom-card">
        <div className="row g-3">
          {/* Main Content Section */}
          <div className="col-md-8">
            <div className="row g-3">
              {/* Image Section */}
              <div className="col-md-4">
                <img
                  src={data.image}
                  alt={data.name}
                  className="img-fluid custom-card-img"
                />
              </div>
              {/* Text Content Section */}
              <div className="col-md-8">
                <div className="custom-card-body">
                  <h1 className="custom-card-title">{data.name}</h1>
                  <h4 className="custom-card-subtitle">{data.phone_number}</h4>
                  <p className="custom-card-text">
                    <strong>Address:</strong> {data.address}
                  </p>
                  <Map location={data.address} />
                  <p className="custom-card-text">
                    <strong>Service Settings:</strong>
                  </p>
                  <ul>
                    {data.service_settings.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                  <p className="custom-card-text">
                    <strong>Payment, Insurance, or Funding Accepted:</strong>
                  </p>
                  <ul>
                    {data.payment.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                  <p className="custom-card-text">
                    <strong>Website: </strong>
                    <a
                      href={data.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="custom-card-link"
                    >
                      {data.website}
                    </a>
                  </p>
                  <Link to="/HelpCenter" className="btn btn-primary mt-3">
                    Back to Help Centers
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Relevant Cards Section */}
          <div className="col-md-3">
            <div className="relevant-cards-container">
              <p>
                <strong>Closest Support Group:</strong>
              </p>
              <div className="relevant-card-wrapper">
                <SupportGroupCardDyn id={data.closest_link_id !== "None" ? data.closest_link_id : data.id % 80} />
              </div>
              <p>
                <strong>Relevant Substances:</strong>
              </p>
              <div className="relevant-card-wrapper">
                <DrugTypeCardDyn id={data.id % 80} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default HelpCenterInstance;
