import React, { useState, useEffect } from "react";
import "./Carousel.css"; // Custom CSS for your carousel

const RandomCardCarousel = ({ component: CardComponent, maxId }) => {
  
  const [currentIndex, setCurrentIndex] = useState(1);
  const [nextCardIndex, setNextCardIndex] = useState(2);
  const [previousCardIndex, setPreviousCardIndex] = useState(3);
  const [nextNextCardIndex, setNextNextCardIndex] = useState(4);
  const [isPageVisible, setIsPageVisible] = useState(true); 

  // Function to cycle forward and set random index
  useEffect(() => {

    // Function to get a random index between 0 and maxId - 1
    const getRandomIndex = () => {
        while (true) {
            const rnd = Math.floor(Math.random() * maxId) + 1;
            if (rnd === currentIndex || rnd === nextCardIndex || rnd === previousCardIndex || rnd === nextNextCardIndex) {
                continue;
            } else
                return rnd;
        }
    };


    const cycleForward = () => {
      console.log("Cycle forward");
      if (!isPageVisible) return; // Pause if the page is hidden

      setPreviousCardIndex(currentIndex); // Set previous index to the current index
      setCurrentIndex(nextCardIndex); // Set current index to next preloaded index
      setNextCardIndex(nextNextCardIndex); // Set next index to the next preloaded index
      setNextNextCardIndex(getRandomIndex()); // Preload the next next index

      console.log("currentIndex:", currentIndex);
    };

    const intervalId = setInterval(cycleForward, 6000); // Change card every 6 seconds
    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [currentIndex, nextCardIndex, nextNextCardIndex, isPageVisible, maxId, previousCardIndex]);

  // Listen for visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log("Page is hidden");
        setIsPageVisible(false); // Pause when the page is hidden
      } else {
        console.log("Page is visible");
        setIsPageVisible(true); // Resume when the page becomes visible
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange); // Clean up the event listener on unmount
    };
  }, [isPageVisible]);

  // Generate the list of cards (previous, current, next)
  const cards = Array.from({ length: maxId }, (_, i) => (
    <div
      key={i}
      className={`carousel-card ${i === previousCardIndex ? "previous" : ""} ${
        i === currentIndex ? "active" : ""
      } ${i === nextCardIndex ? "next" : ""} ${
        i === nextNextCardIndex ? "hidden" : ""
      }`}
    >
      <CardComponent id={i} />
    </div>
  ));

  return (
    <div className="carousel-container">
      {cards.filter((_, i) =>
        [
          previousCardIndex,
          currentIndex,
          nextCardIndex,
          nextNextCardIndex,
        ].includes(i)
      )}{" "}
      {/* Only show 3 cards: previous, current, and next */}
    </div>
  );
};

export default RandomCardCarousel;
