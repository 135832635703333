import React from "react";
import HelpCenterCard from "../../components/HelpCenterCard";
// import { helpCenterData } from "../Data";
import "./Model.css";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingIndicator from "../../components/loading_indicator";

const HelpCenterModel = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const query = queryParams.get("search");
  if (query === null) {
    queryParams.set("search", "");
    window.location.search = queryParams.toString();
  }

  const [data, setData] = useState(null);
  const [current_id, setCurrentId] = useState(1);
  const [total_cards, setTotalCards] = useState(0);
  const cards_per_page = 12;

  const [queryInput, setQueryInput] = useState(query);
  const [searchQuery, setSearchQuery] = useState(query);
  const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [sort, setSort] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      // load the data from the API as json
      console.log("Fetching data from API...");
      const data_response = await axios
        .get(
          "https://api.txsubstancefree.me/helpcenters/helpCentersRange/",
          {
            params: {
              start: current_id,
              end: current_id + cards_per_page - 1,
              search_by: searchQuery,
              filter_by: filter,
              search_filter: searchFilter,
              sort_by: sort,
            },
          }
        )
        .then((response) => {
          setData(response.data.records);
          setTotalCards(response.data.count);
          console.log("Data fetched from API:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching data from API:", error);
        });
      console.log(data_response);
    };

    fetchData();
  }, [current_id, searchQuery, filter, searchFilter, sort]);

  const changePage = (new_id) => {
    if (new_id < 1 || new_id > total_cards)
      return;
    setData(null);
    setCurrentId(new_id);
  };

  if (!data) {
    return (
      <div className="body">
        <div className="model-header">Help Centers</div>
        <LoadingIndicator />
      </div>
    );
  }

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setCurrentId(1);
  };

  const handleSearchFilterChange = (e) => {
    setSearchFilter(e.target.value);
    setCurrentId(1);
  };

  const handleSortDropdownChange = (e) => {
    setSort(e.target.value);
    setCurrentId(1);
  }

  const handleSearch = () => {
    setSearchQuery(queryInput);
    setCurrentId(1);
  };

  return (
    <>
      <div className="body">
        <div className="model-header">Help Centers</div>

        <div className="search-filter-container">

          <div className="search-container">
            {/* Search Bar */}
            <select value={searchFilter} onChange={handleSearchFilterChange} className="dropdown" style={{marginRight: "10px"}}>
              <option value="">Search By</option>
              <option value="name">Name</option>
              <option value="city">City</option>
              <option value="services">Services Provided</option>
              <option value="phone">Phone</option>
            </select>
            <input
              type="text"
              className="search-bar"
              placeholder="Search Help Centers"
              value={queryInput}
              onChange={(e) => setQueryInput(e.target.value)}
            />
            <button type="button" className="submit-button" onClick={handleSearch}>
              Search
            </button>
          </div>

          <div className="dropdown-container">
            {/* Filter Dropdown */}
            <select value={filter} onChange={handleFilterChange} className="dropdown">
              <option value="">Filter By</option>
              <option value="Intensive outpatient treatment">Intensive</option>
              <option value="Outpatient detoxification">Detoxification</option>
              <option value="Outpatient methadone/buprenorphine or naltrexone treatment">Methadone Treatment</option>
              <option value="Short-term residential">Short-Term</option>
              <option value="Long-term residential">Long-Term</option>
            </select>

            {/* Search Dropdown */}
            <select value={sort} onChange={handleSortDropdownChange} className="dropdown">
              <option value="">Sort By</option>
              <option value="name">Name</option>
              <option value="city">City</option>
            </select>
          </div>
        
        </div>

        <div className="grid-container">
          {data.map((help, index) => {
            return (
              <div className="card_style" key={index}>
                <HelpCenterCard {...help} 
                searchQuery={searchQuery}
                searchFilter={searchFilter}
                />
              </div>
            );
          })}
        </div>
        <div className="pagination">
          <button
            className="pagination-button"
            onClick={() => changePage(current_id - cards_per_page)}
          >
            Previous
          </button>
          <p className="pagination-text">
          Showing {current_id} - {Math.min(current_id + cards_per_page - 1, total_cards)} of {total_cards}{" "}
            Instances
          </p>
          <button
            className="pagination-button"
            onClick={() => changePage(current_id + cards_per_page)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default HelpCenterModel;
