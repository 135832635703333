import "./Pages.css";
import axios from "axios";
import ethanImage from "../images/EthanZhang.jpg";
import mkImage from "../images/MakyraKim.jpg";
import shreyaImage from "../images/ShreyaPalivela.jpg";
import carsonImage from "../images/CarsonStark.jpg";
import dennisImage from "../images/DennisKostjuhin.jpg";
import TeamMemberCard from "../components/TeamMemberCard";
import React, { useState, useEffect } from "react";

const apiUrl = "https://gitlab.com/api/v4";
const projectToken = "glpat-JA9nAywM61TLeMkZQze5";
const projectId = "61983684";

// Map each member to their possible email addresses
const memberEmails = {
  "Makyra Kim": ["makyra20412@gmail.com", "makyrakim@utexas.edu"], // Add other emails as needed
  "Dennis Kostjuhin": ["dennis04@cs.utexas.edu", "orcadennis@outlook.com"],
  "Shreya Palivela": ["shreya_palivela@yahoo.com"],
  "Carson Stark": ["carsonstark@ymail.com"],
  "Ethan Zhang": ["ethanhzhang15@utexas.edu"],
};

const group_members = [
  {
    name: "Makyra Kim",
    gitlab: "makyrakim",
    bio: "Makyra is a Junior at UT Austin majoring in CS and minoring in Applied Statistical Modeling.",
    role: "Fullstack",
    imageSrc: mkImage,
  },
  {
    name: "Dennis Kostjuhin",
    gitlab: "orcadennis",
    bio: "Dennis is a Junior at UT Austin majoring in CS.",
    role: "Backend",
    imageSrc: dennisImage,
  },
  {
    name: "Shreya Palivela",
    gitlab: "shreyapal007",
    bio: "Shreya is a Junior at UT Austin majoring in CS and minoring in Business.",
    role: "Backend, Domain Maintenance",
    imageSrc: shreyaImage,
  },
  {
    name: "Carson Stark",
    gitlab: "Carson-Stark",
    bio: "Carson is a junior at UT Austin majoring in Computer Science and minoring in Business and Robotics.",
    role: "Frontend, User Stories",
    imageSrc: carsonImage,
  },
  {
    name: "Ethan Zhang",
    gitlab: "ethanhzhang15",
    bio: "Ethan is a Junior at UT Austin majoring in CS.",
    role: "Fullstack",
    imageSrc: ethanImage,
  },
];

function About() {
  const [commits, setCommits] = useState(new Map());
  const [issues, setIssues] = useState(new Map());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gitIds = group_members.map((member) => member.gitlab);

        // Dealing with commits
        const allCommits = [];
        let page = 1;
        let hasMoreCommits = true;
        while (hasMoreCommits) {
          const commitsResponse = await axios.get(
            `${apiUrl}/projects/${projectId}/repository/commits`,
            {
              headers: { Authorization: `Bearer ${projectToken}` },
              params: { page, per_page: 100 }, // Fetch up to 100 commits per page
            }
          );

          if (commitsResponse.data.length > 0) {
            allCommits.push(...commitsResponse.data);
            page++;
          } else {
            hasMoreCommits = false; // No more commits to fetch
          }
        }

        const userToCommits = new Map();
        for (const member of group_members) {
          userToCommits.set(member.name, 0);
        }
        allCommits.forEach((commit) => {
          // Check against all emails for each member
          for (const [memberName, emails] of Object.entries(memberEmails)) {
            if (emails.includes(commit.committer_email)) {
              userToCommits.set(memberName, userToCommits.get(memberName) + 1);
              break; // Stop checking once a match is found
            }
          }
        });

        // Dealing with issues now
        const allIssues = [];
        let issuePage = 1;
        let hasMoreIssues = true;

        while (hasMoreIssues) {
          const issuesResponse = await axios.get(
            `${apiUrl}/projects/${projectId}/issues`,
            {
              headers: { Authorization: `Bearer ${projectToken}` },
              params: { page: issuePage, per_page: 100 }, // Fetch up to 100 issues per page
            }
          );

          if (issuesResponse.data.length > 0) {
            allIssues.push(...issuesResponse.data);
            issuePage++;
          } else {
            hasMoreIssues = false; // No more issues to fetch
          }
        }

        const userToIssues = new Map(gitIds.map((id) => [id, 0]));
        allIssues.forEach((issue) => {
          const member = gitIds.find((id) => id === issue.author.username);
          if (member) {
            userToIssues.set(member, userToIssues.get(member) + 1);
          } else {
            console.log(
              `No matching member for issue: ${issue.author.username}`
            );
          }
        });

        setCommits(userToCommits);
        setIssues(userToIssues);
      } catch (error) {
        console.error("Error fetching data from GitLab API:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="about-container">

      <div className="about-panel">

      <h1 className="about-title">Our Mission</h1>
      <div className="site-info content">
        <p>
          <strong>TXSubstanceFree</strong> addresses the substance abuse crisis
          in Texas and supports formally or currently addicted individuals by
          providing information on rehabilitation facilities, support
          groups/organizations, and various drug types and its symptoms.
        </p>
        <p>
          Through this site, the team hopes to provide those who are suffering
          with the utmost help we can offer and support them throughout their
          long but meaningful journey to becoming addiction-free.
        </p>
      </div>
      <h1 className="about-title">Our Team</h1>

      <div className="team-members">
        {group_members.map((member) => (
          <TeamMemberCard
            key={member.name}
            name={member.name}
            image={member.imageSrc}
            bio={member.bio}
            commits={commits.get(member.name) || 0} // Get commits from state
            issues={issues.get(member.gitlab) || 0} // Get issues from state
            responsibility={member.role}
          />
        ))}
      </div>

      <h1 className="about-title">Interesting Results</h1>
      <div className="site-info content">
        <p>
          By integrating various, disparate data sources into three model
          pages—Help Centers, Support Groups, and Substances—our team was able
          to understand and recognize which information is actually useful for
          people suffering from substance abuse and how, as a community, we
          could make the substance-free process easier.
        </p>
      </div>

      <h1 className="about-title">Data Sources and Tools</h1>
      <div className="data-sources content">
        -{" "}
        <a href="https://catalog.data.gov/dataset/national-survey-on-drug-use-and-health-nsduh-2015">
          National Survey on Drug Use and Health (NSDUH)
        </a>
        <br />-{" "}
        <a href="https://www.datafiles.samhsa.gov/datasetnational-survey-substance-abuse-treatment-services-2020-n-ssats-2020-ds0001">
          National Survey of Substance Abuse Treatment Services (N-SSATS)
        </a>
        <br />-{" "}
        <a href="https://findtreatment.gov/locator">
          Rehab/Treatment center information
        </a>
        <br />-{" "}
        <a href="https://www.psychologytoday.com/us/groups/texas?category=substance-abuse">
          Support center (TX) information
          </a>
        <br /> - Wikipedia for substance descriptions
        <br /> - Bing search for images
        <br />
        - Google Maps API (RESTful) for help centers/support groups location
        display
        <br />
        <strong>Programming Languages:</strong>
        <br />- Backend: Python
        <br />- Frontend: Typescript, CSS, Javascript
        <br />
        <strong>Hosting:</strong> AWS Amplify
        <br />
        <strong>Backend Libraries:</strong> AWS Lambda, API Gateway
        <br />
        <strong>Frontend Libraries:</strong> React
        <br />
        <strong>Documentation:</strong> Postman
        </div>

         <h1 className="about-title">Developer Links</h1>
          <a href="https://gitlab.com/shreyapal007/cs373group10">
            GitLab Repository
        </a>
        <br />
          <a href="https://documenter.getpostman.com/view/34807840/2sAXxLDEku">
            API Documentation
        </a>
        
      </div>

    </div>
  );
}

export default About;
