import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer , PieChart , Pie , Cell, Legend , RadialBar , RadialBarChart} from "recharts";
import "./Pages.css";



const texasRegions = {
  "East Texas": [
    "Anderson", "Angelina", "Bowie", "Camp", "Cherokee", "Gregg", "Harrison", "Houston", "Jasper", "Jefferson", "Lamar", "Limestone", "Nacogdoches", "Panola", "Piney Woods", "Rusk", "Sabine", "San Augustine", "Shelby", "Smith", "Trinity", "Upshur", "Van Zandt", "Wood"
  ],
  "West Texas": [
    "Andrews", "Brewster", "Crane", "Culberson", "Ector", "El Paso", "Glasscock", "Howard", "Jeff Davis", "Loving", "Marfa", "Martin", "Midland", "Pecos", "Reagan", "Terrell", "Ward", "Winkler"
  ],
  "Central Texas": [
    "Bell", "Bosque", "Brazos", "Burnet", "Coryell", "Falls", "Fayette", "Freestone", "Hays", "Hill", "Lampasas", "Lee", "Llano", "McLennan", "Mills", "Robertson", "San Saba", "Travis", "Williamson"
  ],
  "North Texas": [
    "Collin", "Dallas", "Denton", "Ellis", "Grayson", "Hood", "Johnson", "Kaufman", "Parker", "Rockwall", "Tarrant", "Wise"
  ],
  "South Texas": [
    "Bexar", "Blanco", "Caldwell", "Comal", "Dimmit", "Frio", "Gillespie", "Guadalupe", "Hidalgo", "Karnes", "La Salle", "Live Oak", "McMullen", "Medina", "Maverick", "Nueces", "Webb", "Wilson", "Zapata", "Zavala"
  ],
  "Gulf Coast": [
    "Brazoria", "Chambers", "Galveston", "Harris", "Jefferson", "Matagorda", "Orange", "Victoria"
  ],
  "Panhandle": [
    "Amarillo", "Baylor", "Briscoe", "Castro", "Childress", "Cochran", "Collingsworth", "Dallam", "Donley", "Gray", "Hale", "Hansford", "Hutchinson", "Lipscomb", "Lubbock", "Oldham", "Parmer", "Potter", "Randall", "Roberts", "Swisher", "Terry", "Wheeler", "Yoakum"
  ]
};

const groupCountiesByRegion = (counties) => {
  const regionCount = {
    "East Texas": 0,
    "West Texas": 0,
    "Central Texas": 0,
    "North Texas": 0,
    "South Texas": 0,
    "Gulf Coast": 0,
    "Panhandle": 0
  };

  counties.forEach((county) => {
    for (const region in texasRegions) {
      if (texasRegions[region].includes(county)) {
        regionCount[region] += 1;
      }
    }
  });

  // Convert the region count object to an array for visualization
  return Object.entries(regionCount).map(([region, count]) => ({
    region,
    count
  }));
};

function ProviderVisualization() {
  const [foodDesertsJson, setFoodDesertsJson] = useState(null); // State for food deserts JSON
  const [foodResourcesJson, setFoodResourcesJson] = useState(null); // State for food resources JSON
  const [serviceProvidersJson, setServiceProvidersJson] = useState(null); // State for service providers JSON
  const [loading, setLoading] = useState({
    deserts: true,
    resources: true,
    providers: true,
  }); // State to track loading
  const [error, setError] = useState({
    deserts: null,
    resources: null,
    providers: null,
  }); // State to track errors

  // Fetch food deserts JSON
  const fetchFoodDeserts = async () => {
    try {
      const response = await fetch(
        "https://www.foodoasistexas.me/api/food-deserts?page=1&per_page=5238"
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setFoodDesertsJson(data); // Store raw JSON
    } catch (err) {
      console.error("Error fetching food deserts:", err);
      setError((prev) => ({ ...prev, deserts: "Failed to fetch food deserts." }));
    } finally {
      setLoading((prev) => ({ ...prev, deserts: false }));
    }
  };

  // Fetch food resources JSON
  const fetchFoodResources = async () => {
    try {
      const response = await fetch(
        "https://www.foodoasistexas.me/api/food-resources?page_number=1&per_page=728"
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setFoodResourcesJson(data); // Store raw JSON
    } catch (err) {
      console.error("Error fetching food resources:", err);
      setError((prev) => ({ ...prev, resources: "Failed to fetch food resources." }));
    } finally {
      setLoading((prev) => ({ ...prev, resources: false }));
    }
  };

  // Fetch service providers JSON
  const fetchServiceProviders = async () => {
    try {
      const response = await fetch(
        "https://www.foodoasistexas.me/api/service-providers?page_number=1&per_page=800"
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setServiceProvidersJson(data); // Store raw JSON
    } catch (err) {
      console.error("Error fetching service providers:", err);
      setError((prev) => ({ ...prev, providers: "Failed to fetch service providers." }));
    } finally {
      setLoading((prev) => ({ ...prev, providers: false }));
    }
  };

  useEffect(() => {
    fetchFoodDeserts();
    fetchFoodResources();
    fetchServiceProviders();
  }, []);

  // Prepare data for the bar chart for food deserts
  const prepareFoodDesertsBarChartData = () => {
    if (!foodDesertsJson || !foodDesertsJson.food_deserts) return [];

    const countyCounts = foodDesertsJson.food_deserts.reduce((acc, desert) => {
      acc[desert.County] = (acc[desert.County] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(countyCounts).map(([county, count]) => ({
      county,
      count,
    }));
  };

  // Prepare data for the bar chart for food resources
  const prepareFoodResourcesRadialBarChartData = () => {
    if (!foodResourcesJson || !foodResourcesJson.food_resources) return [];
  
    const categoryCounts = foodResourcesJson.food_resources.reduce((acc, resource) => {
      acc[resource.main_category] = (acc[resource.main_category] || 0) + 1;
      return acc;
    }, {});
  
    return Object.entries(categoryCounts).map(([main_category, count]) => ({
      main_category,
      count,
    }));
  };

  const prepareServiceProvidersPieChartData = () => {
  if (!serviceProvidersJson || !serviceProvidersJson.service_providers) return [];

  console.log(serviceProvidersJson.service_providers);

  const counties = serviceProvidersJson.service_providers.map(provider => provider.county);
  console.log(counties);
  const groupedData = groupCountiesByRegion(counties);

  // Check the structure of the returned data

  return groupedData;
};

  const serviceProvidersPieChartData = prepareServiceProvidersPieChartData();

  const foodDesertsBarChartData = prepareFoodDesertsBarChartData();
  const foodResourcesRadialBarChartData = prepareFoodResourcesRadialBarChartData();

  const regionColors = {
    "East Texas": "#ff7f0e",   // Orange
    "West Texas": "#2ca02c",   // Green
    "Central Texas": "#1f77b4",// Blue
    "North Texas": "#9467bd",  // Red
    "South Texas": "#e377c2",  // Purple
    "Gulf Coast": "#8c564b",   // Brown
    "Panhandle": "#d62728"     // Pink
  };

  return (
    <div className="body">
      <div className="model-header">Provider Visualizations</div>

      <div className="visualization-body">
        {/* Food Deserts Bar Chart */}
        <div className="data-section">
          <h3>Food Deserts by County</h3>
          {loading.deserts && <p>Loading food deserts...</p>}
          {error.deserts && <p className="error">{error.deserts}</p>}
          {!loading.deserts && !error.deserts && foodDesertsBarChartData.length > 0 && (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={foodDesertsBarChartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="county" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        {/* Food Resources Radial Bar Chart */}
        <div className="data-section">
          <h3>Food Resources by Main Category</h3>
          {loading.resources && <p>Loading food resources...</p>}
          {error.resources && <p className="error">{error.resources}</p>}
          {!loading.resources && !error.resources && foodResourcesRadialBarChartData.length > 0 && (
            <ResponsiveContainer width="100%" height={1000}>
              <RadialBarChart
                innerRadius="10%"
                outerRadius="80%"
                data={foodResourcesRadialBarChartData}
                startAngle={180}
                endAngle={-180}
              >
                <RadialBar
                  minAngle={15}
                  background
                  clockWise
                  dataKey="count"
                />
                <Tooltip
                  content={({ payload }) => {
                    if (payload && payload.length > 0) {
                      const { main_category, count } = payload[0].payload;
                      return (
                        <div className="custom-tooltip">
                          <p>{`${main_category}: ${count}`}</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </RadialBarChart>
            </ResponsiveContainer>
            )}
        </div>

        {/* Service Providers by Region Pie Chart */}
        <div className="data-section">
          <h3>Service Providers by Region</h3>
          {loading.providers && <p>Loading service providers...</p>}
          {error.providers && <p className="error">{error.providers}</p>}
          {!loading.providers && !error.providers && serviceProvidersPieChartData.length > 0 && (
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={serviceProvidersPieChartData}
                  dataKey="count"
                  nameKey="region"
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  label
                >
                  {serviceProvidersPieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={regionColors[entry.region]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>

        {/* Critique Section */}
        <div className="critique-section">
          <h3>Other Critiques</h3>

          <h4>What did they do well?</h4>
          <p>
            They did a really good job of making their website look really good
            and easy to navigate. The searching, sorting, and filtering is also
            very clean and works pretty quickly.
          </p>

          <h4>How effective was their RESTful API?</h4>
          <p>
            Their RESTful API was very effective. It had good documentation and
            had all 3 models separated and easy to access.
          </p>

          <h4>How well did they implement your user stories?</h4>
          <p>
            They implemented our user stories pretty well. All of the things
            that we suggested that were in scope were implemented, and it helped
            to improve the website and make it better for the community they are
            serving.
          </p>

          <h4>What did we learn from their website?</h4>
          <p>
            We learned a lot about how to format our cards and make them look more
            appealing and about making data sources and tools look better.
          </p>

          <h4>What can they do better?</h4>
          <p>
            At the time of reviewing, their global search removed their images
            and seemed to break the formatting of the cards. There were also
            some stats that were not included such as “Low Income & Low Access
            (1 mile, 20%):“, though this could just be a data issue of each
            instance not having all the same data.
          </p>

          <h4>What puzzles us about their website?</h4>
          <p>
            We were puzzled about why there is no image for service providers,
            and why the map images on a lot of the food deserts cards are the
            same, without any specifications for the areas.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProviderVisualization;
