import { Link } from "react-router-dom";
import "./Card.css";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingIndicator from "./loading_indicator";

const SupportGroupCardDyn = ({ id }) => {

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // load the data from the API as json
      console.log("Fetching data from API...");
      const instance_id = parseInt(id);
      const data_response = await axios.get('https://api.txsubstancefree.me/supportgroups/supportGroupById/', { params: { id: instance_id } })
        .then((response) => {
          setData(response.data);
          console.log("Data fetched from API:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching data from API:", error);
        });
      console.log(data_response);
    }

    fetchData();
  }, [id]);

  if (!data) {
    return (
      <div className="container mt-5">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="card">
      <Link to={"/SupportGroup/" + id} style={{ textDecoration: "none", color: "inherit" }}>
        <img
          src={data.imgurl}
          alt={data.groupname}
          className="card-img-top"
          style={{ maxHeight: "200px", objectFit: "cover" }}
        />
        <div className="card-body">
          <h5 className="card-title">{data.groupname}</h5>
          <p className="card-text">
            <strong>Location:</strong> {data.location}
          </p>
          <p className="card-text">
            <strong>Expertise:</strong> {data.expertise.join(", ")}
          </p>
          <p className="card-text">
            <strong>Availability:</strong> {data.availability}
          </p>
          <p className="card-text">
            <strong>Phone Number:</strong> {data.phone}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default SupportGroupCardDyn;
