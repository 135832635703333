import { Link } from "react-router-dom";
import "./Pages.css";
import RandomCardCarousel from "../components/RandomCardCarousel";
import DrugTypeCardDyn from "../components/DrugTypeCardDyn";
import HelpCenterCardDyn from "../components/HelpCenterCardDyn";
import SupportGroupCardDyn from "../components/SupportGroupCardDyn";

function Splash() {
  return (
    <div>
      <div className="splash-container">
        <div className="splash-content">
          <h1 className="splash-title">TX Substance Free</h1>
          <p className="splash-subtitle">
            Supporting Texans on the path to a healthier, substance-free life.
          </p>
        </div>
      </div>

      <div className = "splash-section">

        <p className="splash-heading" style={{marginBottom:"3rem"}} >Explore Resources</p>
        <div className="splash-columns">
          <div className="splash-column">
            <Link to="/HelpCenter">
              <p className="splash-subheading">Help Centers</p>
              <img src="https://pvu.thebluebook.com/inc/img/qp/1635450/danis-building-construction-co-river-city-rehabilitation-center1.png"
                alt="Help Centers" className="splash-image" />
              <p className="splash-description">Find a rehabilitation center near you. </p>
            </Link>
          </div>
          <div className="splash-column">
            <Link to="/SupportGroup">
              <p className="splash-subheading">Support Groups</p>
              <img src="https://freebythesea.com/wp-content/uploads/2023/04/What-Are-Support-Groups-for-Substance-Abuse_-.jpeg"
                alt="Support groups" className="splash-image" />
              <p className="splash-description">Connect with others on the same journey. </p>
            </Link>
            </div>
          <div className="splash-column">
            <Link to="/DrugType">
              <p className="splash-subheading">Substance Types</p>
              <img src="https://medicine.washu.edu/app/uploads/2023/03/SubstanceUseDisordersTreatment.jpg"
                alt="Substance Types" className="splash-image" />
              <p className="splash-description">Learn about different types of substances and their risks. </p>
            </Link>
          </div>
        </div>
      </div>

      <div className="splash-section">
        <p className="splash-heading">Featured Help Centers</p>
        <RandomCardCarousel component={HelpCenterCardDyn} maxId={80} />
      </div>

      <div className="splash-section">
        <p className="splash-heading">Featured Support Groups</p>
        <RandomCardCarousel component={SupportGroupCardDyn} maxId={80} />
      </div>

      <div className="splash-section">
        <p className="splash-heading">Featured Substance Types</p>
        <RandomCardCarousel component={DrugTypeCardDyn} maxId={50} />
      </div>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-links">
          <p className="footer-heading">Quick Links</p>
          <Link to="/" className="footer-link">Home</Link>
          <Link to="/About" className="footer-link">About</Link>
          <Link to="/HelpCenter" className="footer-link">Help Centers</Link>
          <Link to="/SupportGroup" className="footer-link">Support Groups</Link>
          <Link to="/DrugType" className="footer-link">Substance Types</Link>
        </div>
      </footer>
  
    </div>
    
  );
}

export default Splash;
