import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Map from "../../components/Map";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Instance.css";
import HelpCenterCardDyn from "../../components/HelpCenterCardDyn";
import DrugTypeCardDyn from "../../components/DrugTypeCardDyn";
import axios from "axios";
import { useState, useEffect } from "react";
import LoadingIndicator from "../../components/loading_indicator";

function SupportGroupInstance() {
  const { id } = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // load the data from the API as json
      console.log("Fetching data from API...");
      const instance_id = parseInt(id);
      const data_response = await axios
        .get(
          "https://api.txsubstancefree.me/supportgroups/supportGroupById/",
          { params: { id: instance_id } }
        )
        .then((response) => {
          setData(response.data);
          console.log("Data fetched from API:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching data from API:", error);
        });
      console.log(data_response);
    };

    fetchData();
  }, [id]);

  if (!data) {
    return (
      <div className="container mt-5">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="custom-card">
        <div className="row g-0">
          {/* Main Content Section */}
          <div className="col-md-9">
            <div className="row g-3">
            {/* Image Section */}
            <div className="col-md-4">
              <img
                src={data.imgurl}
                alt={data.groupName}
                className="img-fluid custom-card-img"
              />
            </div>
            {/* Text Content Section */}
            <div className="col-md-8">
              <div className="custom-card-body">
                <h1 className="custom-card-title">{data.groupname}</h1>
                <h4 className="custom-card-subtitle">{data.hostname}</h4>
                <p className="custom-card-text">
                  <strong>Location:</strong> {data.location}
                </p>
                <p className="custom-card-text">
                  <strong>Address:</strong> {data.address}
                </p>

                {/* Use the reusable Map component */}
                <Map location={data.address} />

                <p className="custom-card-text">
                  <strong>Expertise:</strong>
                </p>
                <ul className="custom-card-text">
                  {data.expertise.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <p className="custom-card-text">
                  <strong>Availability:</strong> {data.availability}
                </p>
                <p className="custom-card-text">
                  <strong>Phone:</strong> {data.phone}
                </p>
                <p className="custom-card-text">
                  <strong>Description:</strong> {data.description}
                </p>
                <p className="custom-card-text">
                  <strong>Website: </strong>
                  <a
                    href={data.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="custom-card-link"
                  >
                    {data.website}
                  </a>
                </p>

                <Link to="/SupportGroup" className="btn btn-primary mt-3">
                  Back to Support Groups
                </Link>

              </div>
            </div>
          </div>
          </div>
          
          {/* Relevant Links Section */}
          <div className="col-md-3">
            <div className="relevant-cards-container">
                <p className="customer-card-text">
                  <strong>Closest Help Center:</strong>
                </p>
                <div className="relevant-card-wrapper">
                  <HelpCenterCardDyn id = {data.closest_link_id !== "None" ? data.closest_link_id : data.id % 80} />
                </div>

                <p className="customer-card-text">
                  <strong>Relevant Substances:</strong>
                </p>
                <div className="relevant-card-wrapper">
                  <DrugTypeCardDyn id={4} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default SupportGroupInstance;
