import React, { useEffect, useState } from "react";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer , PieChart, Pie , Cell , RadialBar, RadialBarChart } from 'recharts'; // Import Recharts components
import "./Pages.css";

function Visualization() {
  const [ageFirstUseCount, setAgeFirstUseCount] = useState([]); // State to store counts of age_first_use
  const [locationCount, setLocationCount] = useState([]); // State to store counts of location
  const [supportGroupLocationCount, setSupportGroupLocationCount] = useState([]); // State to store counts of location for support groups

  useEffect(() => {
    // Fetch age_first_use data
    const fetchAgeData = async () => {
      try {
        const response = await axios.get(
          "https://api.txsubstancefree.me/substancetypes/substanceTypesRange",
          {
            params: {
              start: 1,
              end: 100, // Adjust the range as needed
            },
          }
        );

        // Tally the count of each unique age_first_use, excluding age 0
        const ageCount = {};
        response.data.records.forEach((record) => {
          const ageFirstUse = record.age_first_use;
          if (ageFirstUse !== 0) { // Exclude age 0
            ageCount[ageFirstUse] = (ageCount[ageFirstUse] || 0) + 1;
          }
        });

        // Convert ageCount object to an array of objects suitable for Recharts
        const chartData = Object.entries(ageCount)
          .map(([age, count]) => ({ age: age, count: count }))
          .filter(item => item.count > 0); // Ensure counts are greater than 0

        setAgeFirstUseCount(chartData); // Store the chart data in state
      } catch (error) {
        console.error("Error fetching age data:", error);
      }
    };

    // Fetch location data from help centers
    const fetchLocationData = async () => {
      try {
        const response = await axios.get(
          "https://api.txsubstancefree.me/helpcenters/helpCentersRange/",
          {
            params: {
              start: 1,
              end: 500, // Adjust the range as needed
            },
          }
        );

        // Tally the count of each unique location
        const locationCount = {};
        response.data.records.forEach((record) => {
          const location = record.city;
          if (location) { // Ensure location is not empty or null
            locationCount[location] = (locationCount[location] || 0) + 1;
          }
        });

        // Convert locationCount object to an array of objects suitable for Recharts
        const chartData = Object.entries(locationCount)
          .map(([location, count]) => ({ location: location, count: count }))
          .filter(item => item.count > 0); // Ensure counts are greater than 0

        setLocationCount(chartData); // Store the chart data in state
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    // Fetch location data from support groups
    const fetchSupportGroupLocationData = async () => {
      try {
        const response = await axios.get(
          "https://api.txsubstancefree.me/supportgroups/supportGroupsRange",
          {
            params: {
              start: 1,
              end: 100, // Adjust the range as needed
            },
          }
        );

        // Tally the count of each unique location for support groups
        const supportGroupLocationCount = {};
        response.data.records.forEach((record) => {
          let location = record.location;
          if (location) { // Ensure location is not empty or null
            location = location.replace(/\sTX.*$/, "").trim();
            supportGroupLocationCount[location] = (supportGroupLocationCount[location] || 0) + 1;
          }
        });

        // Convert supportGroupLocationCount object to an array of objects suitable for Recharts
        const chartData = Object.entries(supportGroupLocationCount)
          .map(([location, count]) => ({ location: location, count: count }))
          .filter(item => item.count > 0); // Ensure counts are greater than 0

        setSupportGroupLocationCount(chartData); // Store the chart data in state
      } catch (error) {
        console.error("Error fetching support group location data:", error);
      }
    };

    fetchAgeData();
    fetchLocationData();
    fetchSupportGroupLocationData();
  }, []);

  return (
    <div className="body">
      <div className="model-header">Visualizations</div>

      <div className="visualization-body">
        {/* Pie Chart for Age First Use Count */}
        <div className="age-first-use-chart">
          <h3>Age First Use Count</h3>
          {ageFirstUseCount.length > 0 ? (
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={ageFirstUseCount}
                  dataKey="count"
                  nameKey="age"  // Use age as the name
                  cx="50%" 
                  cy="50%" 
                  outerRadius="80%" 
                  labelLine={false}
                  label={({ age }) => age} // Display the age in the label
                >
                  {ageFirstUseCount.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getColor(entry.age)} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <p>No data available for age first use counts.</p>
          )}
        </div>

        {/* Bar Chart for Location Count (Help Centers) */}
        <div className="location-chart">
          <h3>Location Count (Help Centers)</h3>
          {locationCount.length > 0 ? (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={locationCount}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="location" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <p>No data available for location counts (Help Centers).</p>
          )}
        </div>

        {/* Bar Chart for Location Count (Support Groups) */}
        <div className="support-group-location-chart">
          <h3>Location Count (Support Groups)</h3>
          {supportGroupLocationCount.length > 0 ? (
             <ResponsiveContainer width="100%" height={1400}>
             <RadialBarChart
               innerRadius="10%" // Inner radius (the hole in the middle)
               outerRadius="80%" // Outer radius (size of the bars)
               data={supportGroupLocationCount}
               startAngle={180} // Start at the top
               endAngle={-180} // Complete the full circle
             >
               {/* Radial bars */}
               <RadialBar
                 minAngle={15} // Minimum angle for each slice
                 background
                 clockWise
                 dataKey="count" // The count determines how full the slice is
                 cornerRadius="10%" // Rounded corners on bars
                 fill="#ff7300" // Bar color (you can change this)
               />
               {/* Tooltip */}
               <Tooltip
                 content={({ payload }) => {
                   if (!payload || payload.length === 0) return null;
                   const { location, count } = payload[0].payload;
                   return (
                     <div className="custom-tooltip">
                       <p>{`City: ${location}`}</p>
                       <p>{`Count: ${count}`}</p>
                     </div>
                   );
                 }}
               />
             </RadialBarChart>
           </ResponsiveContainer>
         ) : (
           <p>No data available for location counts (Support Groups).</p>
         )}
       </div>

        <h3>Self-Critiques</h3>
        <h4>What did we do well?</h4>
        <p>
          We did a great job of collaborating with each other, giving and
          taking advice and ideas, and helping each other out when we were
          struggling on a part.
        </p>

        <h4>What did we learn?</h4>
        <p>
          We learned a lot about how to work on a project as a team and the
          amount of planning and communication it takes to get projects done
          effectively. We also learned about different aspects of full stack
          development, such as SQL, AWS, APIs, databases, as well as React and
          JavaScript.
        </p>

        <h4>What did we teach each other?</h4>
        <p>
          We taught each other a lot about different technologies we either
          knew about beforehand, or learned for this project. For example,
          Shreya had some knowledge in AWS and was able to show us how to set up
          our website and make everything run smoothly so we could all know what
          was happening and how to fix issues.
        </p>

        <h4>What can we do better?</h4>
        <p>
          We can communicate earlier and more often, and get a plan started
          earlier. Some of the phases felt a bit rushed because we didn’t get
          started on the planning right away, and we all learned that it is
          difficult to complete a phase, or even get started on it, without
          planning it out and assigning tasks to everyone.
        </p>

        <h4>What effect did the peer reviews have?</h4>
        <p>
          I think they had a really good effect on showing people honestly how
          they were doing, as well as reassuring us that we were doing enough
          work. Sometimes it can feel like you aren’t doing that much to
          contribute to the project, or some parts get done without you so you
          feel bad, but hearing that you are doing well from the other people in
          your group helped with any impostor syndrome.
        </p>

        <h4>What puzzles us?</h4>
        <p>
          Something that puzzles us is how our website formats on different
          sizes devices. It would be particularly interesting to see how we
          could change the format for mobile users to work better.
        </p>
      </div>
    </div>
  );
}

const getColor = (age) => {
  if (age <= 18) return "#ff6347"; // Red for ages 18 and under
  if (age <= 25) return "#ff9f00"; // Orange for ages 19-25
  if (age <= 35) return "#f0e000"; // Yellow for ages 26-35
  return "#00bfff"; // Blue for ages 36+
};

export default Visualization;
