import "./App.css";
import Navbar from "./components/Navbar";
import Splash from "./pages/Splash";
import About from "./pages/About";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HelpCenterModel from "./pages/models/HelpCenterModel";
import SupportGroupModel from "./pages/models/SupportGroupModel";
import DrugTypeModel from "./pages/models/DrugTypeModel";
import HelpCenterInstance from "./pages/instances/HelpCenterInstance";
import SupportGroupInstance from "./pages/instances/SupportGroupInstance";
import DrugTypeInstance from "./pages/instances/DrugTypeInstance";
import Visualization from "./pages/Visualization";
import ProviderVisualization from "./pages/ProviderVisualization";


function App() {
  document.title = 'TX Substance Free';

  return (
    <>
      <Router>
        <Navbar/>
        <Routes>
          <Route path="/" Component={Splash} />
          <Route path="/About" Component={About} />
          <Route path="/HelpCenter" Component={HelpCenterModel} />
          <Route path="/SupportGroup" Component={SupportGroupModel} />
          <Route path="/DrugType" Component={DrugTypeModel} />
          <Route path="/Visualization" Component={Visualization} />
          <Route path="/ProviderVisualization" Component={ProviderVisualization} />
          <Route path="/HelpCenter/:id" Component={HelpCenterInstance} />
          <Route path="/SupportGroup/:id" Component={SupportGroupInstance} />
          <Route path="/DrugType/:id" Component={DrugTypeInstance} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
