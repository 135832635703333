import { ClipLoader } from "react-spinners";

const LoadingIndicator = () => {
    return (
        <div className="loading">
            <ClipLoader />
        </div>
    );
}

export default LoadingIndicator;