import { Link } from "react-router-dom";
import { default as nb } from 'react-bootstrap/Navbar';
import React, { useState, useEffect } from "react";
import axios from "axios";

function Navbar() {

    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedQuery, setDebouncedQuery] = useState(""); // To track debounced input
    const [resultsCount, setResultsCount] = useState({});
    const [loading, setLoading] = useState(false);

    const pageUrls = {
        "Help Centers": "/HelpCenter",
        "Support Groups": "/SupportGroup",
        "Drug Types": "/DrugType",
    };

    // Debounce logic: Update `debouncedQuery` after a delay
    useEffect(() => {
        const delayHandler = setTimeout(() => {
            setDebouncedQuery(searchQuery);
        }, 500); // 500ms delay

        return () => clearTimeout(delayHandler); // Cleanup previous timeout
    }, [searchQuery]);

    // Trigger search when `debouncedQuery` updates
    useEffect(() => {
        const fetchSearchResults = async (query) => {
            const urls = {
                "Help Centers": "https://api.txsubstancefree.me/helpcenters/helpCentersRange",
                "Support Groups": "https://api.txsubstancefree.me/supportgroups/supportGroupsRange",
                "Drug Types": "https://api.txsubstancefree.me/substancetypes/substanceTypesRange",
            };

            setLoading(true);
            const results = {};
            for (const [category, url] of Object.entries(urls)) {
                results[category] = await getResultsCount(query, url);
                await new Promise((resolve) => setTimeout(resolve, 10));
            }
            setResultsCount(results);
            setLoading(false);
        };

        if (debouncedQuery.trim()) {
            fetchSearchResults(debouncedQuery.trim());
        } else {
            setResultsCount({});
        }
    }, [debouncedQuery]);

    const getResultsCount = async (query, url) => {
        console.log("Fetching data from API...");
        let count = 0;
        console.log("query:", query);
        await axios
            .get(url, {
                params: {
                    start: 1,
                    end: 1,
                    search_by: query,
                    search_filter: "",
                },
            })
            .then((response) => {
                count = response.data.count;
                console.log("Data fetched from API:", response.data);
            })
            .catch((error) => {
                console.error("Error fetching data from API:", error);
            });
        return count;
    };

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleLinkClick = () => {
        setSearchQuery(""); // Reset the search query to hide the dropdown
        setResultsCount({}); // Clear results
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup"
                            aria-controls="navbarNavAltMarkup"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <nb.Brand href="/">TXSubstanceFree</nb.Brand>
                            <Link className="nav-link" aria-current="page" to="/">
                                Home
                            </Link>
                            <Link className="nav-link" to="/About">
                                About
                            </Link>
                            <Link className="nav-link" to="/HelpCenter">
                                Help Centers
                            </Link>
                            <Link className="nav-link" to="/SupportGroup">
                                Support Groups
                            </Link>
                            <Link className="nav-link" to="/DrugType">
                                Drug Types
                            </Link>
                            <Link className="nav-link" to="/Visualization">
                                Visualization
                            </Link>
                            <Link className="nav-link" to="/ProviderVisualization">
                                Provider Visualization
                            </Link>

                        </div>
                    </div>
                    <div className="ms-auto">
                        <form className="d-flex" role="search">
                        <input
                            style={{ width: "250px" }}
                            className="form-control"    
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            value={searchQuery}
                            onChange={handleInputChange}
                        />
                        </form>
                        {searchQuery && (
                            <div className="dropdown-menu show mt-2 p-2">
                                {loading ? (
                                    <p> Searching... </p>
                                ) : Object.keys(resultsCount).length > 0 ? (
                                        Object.entries(resultsCount).map(([category, count]) => (
                                        <Link
                                            key={category}
                                            to={pageUrls[category] + "?search=" + searchQuery}
                                            className="dropdown-item"
                                            onClick={handleLinkClick}
                                        >
                                            {category}: {count} result{count !== 1 ? "s" : ""}
                                        </Link>
                                    ))
                                ) : (
                                    <div>No results found</div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        </>
    );
}
export default Navbar;
